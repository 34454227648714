import { labelTemplateQR } from "./labelTemplates";
import socketIOClient from "socket.io-client";
const socket = socketIOClient(process.env.REACT_APP_SOCKET_ENDPOINT);

export const printOrderItemLabel = ({ item, settings }) => {
  console.log("* handlePrintOrderItemLabel init");
  return new Promise((resolve) => {
    // console.log("[handlePrintOrderItemLabel] item: ", item);
    // console.log("[handlePrintOrderItemLabel] settings: ", settings);
    const { platens } = settings || [];
    // console.log("[handlePrintOrderItemLabel] platens: ", platens);

    let retValLabel;
    if (!settings.printLabelByGraphicPositions) {
      if (item?._item?._graphics && Boolean(item?._item?._graphics.length)) {
        let tempGraphicPositionArr = [];
        for (let g of item?._item?._graphics) {
          if (g?.graphicPosition)
            tempGraphicPositionArr.push(g.graphicPosition);
        }
        if (Boolean(tempGraphicPositionArr.length)) {
          item._item.graphicPosition = tempGraphicPositionArr.join(",");
        }
      }
      retValLabel = labelTemplateQR({ item, platens, settings });
      retValLabel?.zpl &&
        socket.emit("on-sku-print", { template: retValLabel.zpl }, (cb) => {
          console.log("[handlePrintOrderItemLabel:on-sku-print] cb: ", cb);
          resolve(cb);
        });
    } else {
      if (item?._item?._graphics.length > 1) {
        for (let graphic of item._item._graphics) {
          // console.log('- graphic: ', graphic)
          if (item?._item?.graphicPosition)
            item._item.graphicPosition = graphic.graphicPosition;
          retValLabel = labelTemplateQR({ item, platens, settings });
          retValLabel?.zpl &&
            socket.emit("on-sku-print", { template: retValLabel.zpl }, (cb) => {
              // console.log("[handlePrintOrderItemLabel:on-sku-print] cb: ", cb)
              resolve(cb);
            });
        }
      } else {
        if (item?._item?._graphics.length === 1) {
          item._item.graphicPosition = item._item._graphics[0]?.graphicPosition;
        }

        retValLabel = labelTemplateQR({ item, platens, settings });
        retValLabel?.zpl &&
          socket.emit("on-sku-print", { template: retValLabel.zpl }, (cb) => {
            // console.log("[handlePrintOrderItemLabel:on-sku-print] cb: ", cb)
            resolve(cb);
          });
      }
    }
  });
};
