import React, { memo, useEffect, useRef, useState } from "react";
import _ from "lodash";
import Autosuggest from "react-autosuggest";
import { getSearchResults } from "utils/items";
import ModalTemplate from "./ModalTemplate";
import { FaBolt, FaSave, FaSpinner, FaTrashAlt } from "react-icons/fa";
import { useLocation } from "react-router";
import Button from "@leafygreen-ui/button";
import MappedGraphicsTable from "components/MappedGraphicsTable";
import Tooltip from "@leafygreen-ui/tooltip";
import MappedInventoryTable from "components/MappedInventoryTable";

function ModalProduction({
  modalIsOpen,
  setModalIsOpen,
  title,
  mode,
  item,
  setItem,
  submitHandler,
  handleDelete,
  settings,
  handleGeneratePMT,
  user,
  onAfterCloseModal,
}) {
  // console.log("* ModalProduction init ==");
  // console.log("- ModalItem item", item);
  // item && console.log("mode", mode);
  // console.log("- settings", settings);
  // console.log("- user", user);
  const [searchResults, setSearchResults] = useState([]);
  // const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const [searchLoading, setSearchLoading] = useState(false);
  const [componentValue, setComponentValue] = useState("");
  const location = useLocation();
  // console.log('- location: ', location)

  const formRef = useRef();
  // console.log("formRef", formRef);

  const handleSubmit = (e) => {
    e.preventDefault();
    formRef.current.reportValidity();
    if (formRef.current.checkValidity()) {
      modalIsOpen && setModalIsOpen(false);
      submitHandler();
    }
  };

  const onChangeHandler = (e) => {
    console.log("* onChangeHandler init");
    // console.log(e.target.id, e.target.value);
    setItem({ ...item, [e.target.id]: e.target.value.trimLeft() });
  };

  useEffect(() => {
    item &&
      setComponentValue(
        item?._component?.sku
          ? item._component.sku
          : item?.component
          ? item.component
          : ""
      );
  }, [item]);

  const getSuggestionValue = (suggestion) => {
    console.log("suggestion: ", suggestion);
    if (suggestion) {
      setItem({
        ...item,
        _component: suggestion._id,
        component: suggestion.sku,
      });
    }
    return suggestion.sku;
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    console.log("onSuggestionsFetchRequested value", value);
    setSearchLoading(true);
    getSearchResults({
      searchTxt: value,
      options: { page: 1, limit: 1000 },
      type: "component",
    }).then(({ docs }) => {
      console.log("getSearchResults docs", docs);
      setSearchResults(docs);
      setSearchLoading(false);
      // if (docs.length === 1) setSelectedSuggestion(docs[0]);
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    // console.log("clear");
    setSearchResults([]);
  };

  return (
    <ModalTemplate
      title={title}
      subTitle={mode}
      modalIsOpen={modalIsOpen}
      setModalIsOpen={setModalIsOpen}
      modalSize={{ minWidth: 600, width: "auto", height: "auto" }}
      onAfterCloseModal={onAfterCloseModal}
    >
      <form ref={formRef} className="card modal-form modal-item">
        <div className="d-flex">
          <div className="input-field">
            <label htmlFor="sku">SKU</label>
            <input
              id="sku"
              type="text"
              value={item?.sku ? item.sku : ""}
              onChange={onChangeHandler}
              placeholder="SKU"
              required={true}
              disabled={mode === "update"}
              autoFocus={mode === "create"}
            />
          </div>
          <div className="input-field">
            <label htmlFor="description">Description</label>
            <input
              id="description"
              type="text"
              value={item?.description ? item.description : ""}
              onChange={onChangeHandler}
              placeholder="Description"
            />
          </div>
        </div>
        <div className="d-flex">
          <div className="input-field">
            <label htmlFor="category">Category</label>
            <input
              id="category"
              type="text"
              value={item?.category ? item.category : ""}
              onChange={onChangeHandler}
              placeholder="Category"
            />
          </div>
          <div className="input-field">
            <label htmlFor="color">Color</label>
            <input
              id="color"
              type="text"
              value={item?.color ? item.color : ""}
              onChange={onChangeHandler}
              placeholder="Color"
            />
          </div>
        </div>
        <div className="d-flex">
          <div className="input-field">
            <label htmlFor="size">Size</label>
            <input
              id="size"
              type="text"
              value={item?.size ? item.size : ""}
              onChange={onChangeHandler}
              placeholder="Size"
            />
          </div>
          <div className="input-field">
            <label htmlFor="dimensionalUnit">Dimensional Unit</label>
            <input
              id="dimensionalUnit"
              type="text"
              value={item?.dimensionalUnit ? item.dimensionalUnit : ""}
              onChange={onChangeHandler}
              placeholder="Dimensional Unit"
            />
          </div>
        </div>
        <div className="d-flex">
          <div className="input-field">
            <div className="d-flex justify-content-space">
              <label htmlFor="component">Component *</label>
              {searchLoading && <FaSpinner className="spinner" />}
            </div>
            <Autosuggest
              suggestions={searchResults}
              onSuggestionsFetchRequested={onSuggestionsFetchRequested}
              onSuggestionsClearRequested={onSuggestionsClearRequested}
              // onSuggestionSelected={onSuggestionSelected}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={(suggestion) => <span>{suggestion.sku}</span>}
              inputProps={{
                placeholder: "Component*",
                value: componentValue,
                onChange: (event, { newValue }) => {
                  console.log("newValue: ", newValue);
                  setComponentValue(newValue);
                },
                id: "_component",
              }}
              shouldRenderSuggestions={(value) => {
                console.log(`shouldRenderSuggestions value: ${value}`);
                return value.trim().length > 4;
              }}
              highlightFirstSuggestion={true}
            />
          </div>

          <div className="input-field">
            <label htmlFor="graphicPosition">Graphic Position</label>
            <input
              id="graphicPosition"
              type="text"
              value={item?.graphicPosition ? item.graphicPosition : ""}
              onChange={onChangeHandler}
              placeholder="* Graphic Position"
            />
          </div>
        </div>

        <div className="d-flex">
          <div className="input-field">
            <label htmlFor="ripEnv">Rip Env</label>
            <input
              id="ripEnv"
              type="text"
              value={item?.ripEnv ? item.ripEnv : ""}
              onChange={onChangeHandler}
              placeholder="Rip Env"
            />
          </div>
          {settings && settings?.company?.toLowerCase().includes('wallflower') && (
            <div className="input-field">
              <label htmlFor="polarisRecipe">Recipe(Polaris)</label>
              <input
                id="polarisRecipe"
                type="text"
                value={item?.polarisRecipe ? item.polarisRecipe : ""}
                onChange={onChangeHandler}
                placeholder="Recipe::Polaris"
              />
            </div>  
          )}
          <div className="input-field">
            <Tooltip
              align="right"
              trigger={
                <label htmlFor="imageUrl" style={{ cursor: "pointer" }}>
                  Image Url*
                </label>
              }
              popoverZIndex={999}
              enabled={!_.isNil(item?.imageUrl) && !_.isEmpty(item?.imageUrl)}
            >
              {!_.isNil(item?.imageUrl) && !_.isEmpty(item?.imageUrl) && (
                <div className="card mockup-img">
                  <img
                    className="responsive-img"
                    src={item?.imageUrl}
                    style={{ maxHeight: 200 }}
                    alt={`${item?.description}`}
                  />
                </div>
              )}
            </Tooltip>

            <textarea
              id="imageUrl"
              type="text"
              value={item?.imageUrl ? item.imageUrl : ""}
              onChange={onChangeHandler}
              placeholder="Image Url"
              col="50"
              rows="2"
            />
          </div>
        </div>

        {settings && settings?.usePretreatmentBarcode && (
          <div className="d-flex align-items-center">
            <div className="input-field mr-10">
              <label htmlFor="pretreatmentBarcode">
                Pretreatment Barcode Value
              </label>
              <input
                id="pretreatmentBarcode"
                type="text"
                value={
                  item?.pretreatmentBarcode ? item.pretreatmentBarcode : ""
                }
                onChange={onChangeHandler}
                placeholder="Pretreatment Barcode Value"
              />
            </div>
            <Button
              variant="primaryOutline"
              size="small"
              className="swing-icon"
              aria-label="Generate a pretreatment barcode"
              onClick={(e) => handleGeneratePMT(e, item)}
              leftGlyph={<FaBolt />}
            >
              Generate a pretreatment barcode
            </Button>
          </div>
        )}

        {item && settings && (
          <MappedGraphicsTable
            item={item}
            useARXfile={settings && (settings?.defaultIntegratedAutomation.toLowerCase().includes('gtx') &&
 settings?.useARXfile)}
            usePretreatmentBarcode = {settings && (settings?.usePretreatmentBarcode)}
            platens={settings && settings?.platens}
            media={settings && settings?.media}
            usePolarisRecipe={settings && settings?.usePolarisRecipe}
            usePrintMode={settings && settings?.usePrintMode}
          />
        )}

        {item && <MappedInventoryTable item={item} />}

        <div className="modal-footer">
          {mode === "update" &&
            !location?.pathname.includes("workorder") &&
            user?.role === "admin" && (
              <Button
                variant="danger"
                className="swing-icon"
                onClick={handleDelete}
              >
                <FaTrashAlt />
                Delete
              </Button>
            )}
          <Button
            variant="primary"
            onClick={handleSubmit}
            className="swing-icon"
            leftGlyph={<FaSave />}
          >
            Submit
          </Button>
        </div>
      </form>
    </ModalTemplate>
  );
}

export default memo(ModalProduction);
