import React, { useState, useEffect, useContext } from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery, useMutation, useQueryClient } from "react-query";
import socketIOClient from "socket.io-client";
import { jsonToCSV } from "react-papaparse";
import moment from "moment";
import _ from "lodash";
import { ToastContainer, toast } from "react-toastify";
import { SettingsContext } from "contexts/SettingsContext";
import Pagination from "utils/Pagination";
import { Title } from "utils/Title";
import { errorHandler } from "utils/errorHandler";
import log from "utils/log";
import {skuLabelTemplateQR} from "utils/skuLabelTemplateQR";
import ItemsHeader from "./ItemsHeader";
import {
  deleteItem,
  deleteItemBySku,
  getAllItems,
  getItemDoc,
  getSearchResults,
  submitItem,
} from "utils/items";
import ModalItem from "components/modals/ModalProduction";
import { generatePMTHandler } from "utils/pretreatment";
import ProductionsList from "./ProductionsList";
import PageMainHeader from "components/PageMainHeader";
import { AuthContext } from "contexts/AuthContext";
import PageMenuHeader from "components/PageMenuHeader";
import Search from "components/Search";
import Banner from "@leafygreen-ui/banner";
import { ModalPreloader } from "utils/Preloader";
import { getGraphics } from "utils/graphics";
import { axiosInstance as axios } from "configs/axiosConfig";
import ScrollTop from "utils/ScrollTop";
const title = "Productions";
const socket = socketIOClient(process.env.REACT_APP_SOCKET_ENDPOINT);

const initialItem = {
  sku: "",
  description: "",
  category: "",
  component: "",
  size: "",
  color: "",
  graphicPosition: "",
  ripEnv: "",
  imageUrl: "",
  createdAt: "",
  modifiedAt: "",
  pretreatmentBarcode: "",
  active: true,
  type: "production",
};

const limit = 50;
const selectedItemsType = "production";

const useQueryOptions = {
  refetchOnWindowFocus: false,
  staleTime: 1000 * 60 * 1,
};

const getInventoryAreas = (data) => {
  return new Promise((resolve, reject) => {
    try {
      const url = `/inventory-area`;
      axios.post(url, data).then((res) => {
        resolve(res.data);
      });
    } catch (error) {
      reject(false);
    }
  });
};

export default function Productions() {
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState(initialItem);
  const [mode, setMode] = useState("create");
  const [msg, setMsg] = useState(null);
  const [page, setPage] = useState(1);
  const [searchTxt, setSearchTxt] = useState(null);
  const [selectedExportMode, setSelectedExportMode] = useState("retrieved");
  const { settings } = useContext(SettingsContext);
  const [itemModalIsOpen, setItemModalIsOpen] = useState(false);
  const { user } = useContext(AuthContext);
  const queryClient = useQueryClient();
  // console.log("- settings: ", settings);

  const {
    data: items,
    isLoading,
    isError,
    error,
    isFetching,
    refetch,
  } = useQuery(
    ["goods", page],
    async () =>
      await getAllItems({
        query: { type: selectedItemsType },
        options: { page, limit },
      }),
    {
      ...useQueryOptions,
      enabled: false,
    }
  );

  // console.log('- isLoading: ', isLoading)
  // console.log('- isFetching: ', isFetching)
  useEffect(() => {
    console.log("-page hooks init");
    if (_.isNil(searchTxt)) {
      refetch();
    } else {
      fetchSearchResults.mutate(searchTxt);
    }
    // eslint-disable-next-line
  }, [page, searchTxt]);

  const onBarcodeScanned = async () => {
    socket.on("on-barcode-scanned", async (data) => {
      console.log("- on-barcode-scanned: ", data);
      let dataArr = [];
      let delimiter = ";";
      if (_.includes(data, "*")) delimiter = "*";
      console.log("- delimiter: ", delimiter);
      dataArr = data.split(delimiter);
      const code = dataArr[0];
      console.log("- code", code);
      setPage(1);
      setSearchTxt(code);
      fetchSearchResults.mutate(code);
    });
  };

  const onExportItemsResult = async () => {
    socket.on("on-export-items-result", async ({ result, filePath, error }) => {
      console.log("- on-export-items-result: ", result, filePath, error);
      if (result && filePath) {
        setMsg(`* Items are successfully exported at ${filePath}`);
      } else {
        setMsg("Error occured, please contact Prontflo tech support.");
      }
      setLoading(false);
    });
  };

  const onGeneratePMTResult = async () => {
    socket.on("on-generate-pmt-result", async (result) => {
      console.log("- on-generate-pmt-result: ", result);
      const { message, error } = result;
      if (message) {
        console.log("- onGeneratePMTResult message: ", message);
        setMsg(`* ${message}`);

        log.info(`onGeneratePMTResult ${message} at ${new Date()}`);
      }
      if (error) {
        console.error("- onGeneratePMTResult error: ", error);
        if (error?.message) {
          setMsg(error);
          log.error(`onGeneratePMTResult ${error.message} at ${new Date()}`);
        } else {
          const txt = "Unknown error coccurred while on generate PMT";
          setMsg(txt);
          log.error(`onGeneratePMTResult ${txt} at ${new Date()}`);
        }
      }
      isLoading && setLoading(false);
    });
  };

  //onBarcodeScanned hook
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      onBarcodeScanned();
      setLoading(false);
    }, 1000);

    return () => {
      socket.removeAllListeners(["on-barcode-scanned"]);
    };
    // eslint-disable-next-line
  }, []);

  //onExportItemsResult hook
  useEffect(() => {
    setTimeout(() => {
      onExportItemsResult();
    }, 1000);

    return () => {
      socket.removeAllListeners(["on-export-items-result"]);
    };
    // eslint-disable-next-line
  }, []);

  //onGeneratePMTResult hook
  useEffect(() => {
    setTimeout(() => {
      onGeneratePMTResult();
    }, 1000);

    return () => {
      socket.removeAllListeners(["on-generate-pmt-result"]);
    };
    // eslint-disable-next-line
  }, []);

  const updateItem = useMutation(
    async ({ condition, update }) => {
      return await submitItem({ condition, update });
    },
    {
      onError: (error) => {
        console.log("updateItem error", error);
        const retval = errorHandler(error);
        setMsg(retval);
      },
      onSuccess: (data) => {
        // console.log("updateItem onSuccsess data: ", data);
        // setModalPreloaderIsOpen(false);
        if (searchTxt) {
          fetchSearchResults.mutate(searchTxt);
        } else {
          refetch();
        }
      },
    }
  );

  const showModalHandler = (mode, item) => {
    console.log("* showModalHandler init");
    // console.log("- mode: ", mode);
    // console.log("- item: ", item);
    setMode(mode);
    if (msg) setMsg(null);
    if (mode === "create") {
      setItem(initialItem);
    } else {
      // get graphics
      const query = { sku: item.sku };
      const options = { pagination: false };
      getGraphics({ query, options })
        .then((result) => {
          // console.log('- getGraphics results: ', result)
          item._graphics = result.docs;
          return getInventoryAreas({ query, options });
        })
        .then((result) => {
          // console.log('- getInventoryAreas result: ', result)
          item._inventoryArea = result.docs;
          setItem(item);
        });
    }
    setItemModalIsOpen(true);
  };

  const submitHandler = () => {
    // e.preventDefault();
    // console.log("onsubmit item", item);
    delete item.stock;
    item["modifiedAt"] = new Date();
    if (mode === "create") {
      item["createdAt"] = new Date();
    }

    if (!_.isEmpty(item.sku)) {
      updateItem.mutate({
        condition: { sku: item.sku },
        update: { ...item, modifiedAt: new Date() },
      });
    } else {
      console.log("sku is empty");
      return;
    }
  };

  const deleteHandler = useMutation(
    async () => {
      return await deleteItem(item._id);
    },
    {
      onSuccess: () => {
        if (searchTxt) {
          fetchSearchResults.mutate(searchTxt);
        } else {
          refetch();
        }
      },
    }
  );

  const handleDelete = () => {
    itemModalIsOpen && setItemModalIsOpen(false);
    deleteHandler.mutate();
  };

  const fetchSearchResults = useMutation(
    async (searchTxt) => {
      console.log("* fetchSearchResults init");
      console.log("- searchTxt: ", searchTxt);
      setLoading(true);
      return await getSearchResults({
        searchTxt,
        options: { page, limit },
        type: selectedItemsType,
      });
    },
    {
      onError: (error) => {
        const retval = errorHandler(error);
        setMsg(retval);
        setLoading(false);
      },
      onSuccess: (result) => {
        // console.log("- fetchSearchResults onSuccess result: ", result);
        queryClient.setQueryData(["goods", page], () => {
          return { ...result };
        });
        // setLimit(result?.limit)
        setLoading(false);
      },
    }
  );

  const handleSearch = async (searchTxt) => {
    console.log("* handleSearch init");
    console.log("- searchTxt", searchTxt);
    if (!_.isEmpty(searchTxt)) {
      if (page > 1) {
        alert("Requried to move page 1");
        return;
      }
      setSearchTxt(searchTxt);
      fetchSearchResults.mutate(searchTxt);
    }
  };

  const exportItems = async () => {
    console.log("* exportItems init");
    console.log("- selectedExportMode: ", selectedExportMode);
    setLoading(true);
    setMsg(null);

    let exportItems;

    if (selectedExportMode === "all") {
      setSelectedExportMode("retrieved");
      console.log("- socket.connected: ", socket.connected);

      if (_.isNil(searchTxt)) {
        if (socket.connected) {
          let message =
            "Exporting all items may take some minutes to complete depending on the amount of data. Upon completion, message will appear with file path.";
          let confirm = window.confirm(message);
          if (confirm) {
            setMsg("Downloading...");
            socket.emit("on-export-items");
          } else {
            setLoading(false);
          }
        } else {
          alert(
            "Exporting all items requires a local app. It seems that your station doesn't have a local app installed."
          );
        }
        return;
      } else {
        exportItems = await getSearchResults({
          searchTxt,
          options: { pagination: false },
          type: selectedItemsType,
        });
        if (exportItems?.totalDocs > 2000) {
          setLoading(false);
          alert(
            "The maximum number(2000) of exports has been exceeded. Large amounts of exports can hit the server timeout limit"
          );
          return;
        }
      }
    } else {
      exportItems = _.cloneDeep(items);
    }
    console.log("exportItems", exportItems);
    if (_.has(exportItems, "docs")) exportItems = exportItems.docs;

    let fields = [
      "sku",
      "description",
      "category",
      "color",
      "size",
      "childSKUs",
      "component",
      "graphicPosition",
      "ripEnv",
      "dimensionalUnit",
      "type",
      "imageUrl",
    ];

    if (settings?.usePretreatmentBarcode) {
      fields.push("pretreatmentBarcode");
    }

    if (settings?.company?.toLowerCase()?.includes('wallflower')) {
      fields.push("polarisRecipe");
    }

    const result = jsonToCSV({
      fields,
      data: exportItems,
    });
    // console.log(result);

    const element = document.createElement("a");
    const file = new Blob([result], {
      type: "text/csv",
    });
    element.href = URL.createObjectURL(file);
    element.download = `items_${moment().format("YYYYMMDDHHmm")}.csv`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();

    setLoading(false);
  };

  const importItems = (parsedData) => {
    console.log("* importItems init");

    setLoading(true);
    let tempItems = parsedData.map((item) => item.data);
    // tempItems = tempItems.slice(126883, 126983);

    console.log("- tempItems", tempItems);
    let msg = `Total number of items: ${tempItems.length}`;
    setMsg(msg);

    let i = 0;
    let len = tempItems.length - 1;

    async function loop() {
      const tempItem = tempItems[i];
      // console.log('- tempItem: ', i, tempItem);

      if (!tempItem?.type) {
        tempItem["type"] = selectedItemsType;
      } else {
        if (tempItem?.type !== selectedItemsType) {
          tempItem["type"] = selectedItemsType;
        }
      }
      let sku = tempItem.sku;
      let strChildSKUs = _.has(tempItem, "childSKUs")
        ? tempItem.childSKUs
        : null;

      console.log("- strChildSKUs", strChildSKUs, typeof strChildSKUs);
      console.log("- strChildSKUs includes []", _.includes(strChildSKUs, "[]"));

      if (_.includes(strChildSKUs, "[") || _.includes(strChildSKUs, "]")) {
        strChildSKUs = strChildSKUs.replace("[", "").replace("]", "").trim();
        console.log("- strChildSKUs after replace []", strChildSKUs);
      }

      if (!_.isNil(strChildSKUs) && !_.isEmpty(strChildSKUs)) {
        if (_.includes(strChildSKUs, ",")) {
          let arrChildSKUs = strChildSKUs.split(",").map((child) => {
            const regEx = new RegExp(/^ /)
            // console.log("regEx.test(child): ", regEx.test(child))
            if(regEx.test(child)) {
              return _.trimStart(child)
            } else {
              return child
            }
          })
          tempItem.childSKUs = arrChildSKUs;
        }
      } else {
        tempItem.childSKUs = [];
      }

      // find component is exists

      const componentSKU = tempItem?.component ? tempItem?.component : null;
      if (componentSKU) {
        try {
          const componentDoc = await getItemDoc(componentSKU);
          // console.log('- componentDoc: ', componentDoc)
          if (!componentDoc) {
            await submitItem({
              condition: { sku: componentSKU },
              update: {
                sku: componentSKU,
                type: "component",
                createdAt: new Date(),
              },
            });
          }
        } catch (error) {
          console.error("- getItemDoc error: ", error);
        }
      }

      try {
        const result = await submitItem({
          condition: { sku },
          update: { ...tempItem, modifiedAt: new Date() },
        });
        setMsg(`${i + 1}/${tempItems.length}: ${result.sku} updated.`);
        i += 1;
      } catch (error) {
        console.log("submit item error", error);
        setMsg(`Error occured, try again from this sku: ${sku}`);
        return;
      }

      if (i <= len && _.has(tempItem, "sku")) {
        loop();
      } else {
        setMsg("* Completed import items");
        setLoading(false);
        return;
      }
    }

    if (i <= len) {
      loop();
    }
  };

  const handleOnPageChange = ({ selected }) => {
    // console.log("handleOnPageChange page", selected);
    setPage(selected + 1);
  };

  const handleGeneratePMT = async (e, item) => {
    console.log("* handleGeneratePMT init");
    // console.log("- item: ", item);
    e.preventDefault();
    setLoading(true);
    try {
      const result = await generatePMTHandler(item, settings);
      // console.log("- result: ", result);
      const { pretreatmentBarcode, message } = result;
      if (pretreatmentBarcode) {
        setItem({ ...item, pretreatmentBarcode: result.pretreatmentBarcode });
        updateItem.mutate({
          condition: { sku: item.sku },
          update: { pretreatmentBarcode, modifiedAt: new Date() },
        });
      }
      if (message) {
        toast.error(message, {
          position: "bottom-right",
        });
      }
      setLoading(false);
    } catch (error) {
      console.log("- generatePMTHandler error: ", error);
      setLoading(false);
      toast.error(error, {
        position: "bottom-right",
      });
    }
  };

  /*
  const pmtAllHandler = async () => {
    console.log('* pmtAllHanlder init')
    if (settings?.useLocalRepository) {
      alert('Generation of pretreatment barcode is not available in Use Local Repository mode.')
      return
    }
    console.log("socket.connected: ", socket.connected);
    if (socket.connected) {
      let estimateTime = (items?.totalDocs * 0.5) / 360
      let message = `Total estimated completion time is around ${parseInt(estimateTime)} hours. `
      message = message + 'Upon completion, a done message will appear. '
      message = message + `Cancel's not available in the middle of batch job, and if you want to cancel by force, reboot the system.`
      let confirm = window.confirm(message);
      if (confirm) {
        setMsg(`* Pretreatment barcode generation initiates`)
        socket.emit('on-generate-pmt')
        log.info(`batch generation of pretreatment barcode init at ${new Date()}`)
        setLoading(true);
      }
    } else {
      alert(
        "Sync Order requires a local app. It seems that your station doesn't have a local app installed."
      );
    }
  }
  */

  const printSKULabel = (item) => {
    console.log("* printSKULabel init");
    // console.log("- item: ", item)
    const printQty = prompt("Please enter a number to print", 1);
    let zpl = skuLabelTemplateQR({ settings, item, printQty });
    // console.log('- zpl: ', zpl)
    zpl && socket.emit("on-sku-print", {template: zpl}, cb => {
      console.log("[printSKULabel:on-sku-print] cb: ", cb)
      if (cb?.name === "Error") {
        toast.error(cb?.message, {
          position: "bottom-right",
        });
      } else {
        toast.info(cb?.message, {
          position: "bottom-right",
          autoClose: 1500,
        });
      }
    });
  };

  const batchDelete = (parsedData) => {
    console.log("* batchDelete init");
    try {
      setLoading(true);
      setMsg(null);
      let temp = parsedData.map((item) => item.data);
      let msg = `Total number of finished goods for deleting: ${temp.length}`;
      setMsg(msg);
      let i = 0;
      let len = temp.length - 1;

      async function loop() {
        let sku = temp[i].sku;
        try {
          await deleteItemBySku({ sku });
          // const result = await deleteItemBySku({ sku });
          // console.log("deleteItemBySku result", result);
          setMsg(`${i + 1}/${temp.length}: ${sku} deleted.`);
          i += 1;
        } catch (error) {
          console.log("deleteItemBySku error", error);
          const retval = errorHandler(error);
          setMsg(retval);
          return;
        }

        if (i <= len && _.has(temp[i], "sku")) {
          loop();
        } else {
          setMsg(
            "* Completed batch deletion of the finished goods, click refresh to load a page."
          );
          setLoading(false);
          return;
        }
      }

      if (i <= len) {
        loop();
      }
    } catch (error) {
      const retval = errorHandler(error);
      console.log("- batchDelete error: ", retval);
      setMsg(retval);
    }
  };

  const onAfterCloseModal = () => {
    console.log("* onAfterCloseModal init");
    if (_.isNil(searchTxt)) {
      refetch();
    } else {
      fetchSearchResults.mutate(searchTxt);
    }
  };

  return (
    <>
      <Title title={title} />
      <ToastContainer theme="dark" />
      <PageMainHeader title={title} user={user} settings={settings} />

      <section className="primary productions">
        {isLoading ? (
          <Skeleton count={20} height={50} circle={true} />
        ) : isError ? (
          <Banner variant="danger" className="mb-10">
            {error?.message ? error.message : error}
          </Banner>
        ) : (
          <>
            <PageMenuHeader>
              <ItemsHeader
                showModal={showModalHandler}
                importHandler={importItems}
                exportHandler={exportItems}
                handleSearch={handleSearch}
                selectedExportMode={selectedExportMode}
                setSelectedExportMode={setSelectedExportMode}
                batchDeleteHandler={batchDelete}
                handleDelete={handleDelete}
              />
              <div className="align-right_container">
                <Search handleSearch={handleSearch} />
              </div>
            </PageMenuHeader>

            {items && (
              <Pagination
                handleOnPageChange={handleOnPageChange}
                totalDocs={items?.totalDocs}
                totalPages={items?.totalPages}
                page={page}
                limit={limit}
              />
            )}

            {msg && <Banner className="mb-10">{msg}</Banner>}

            {items && Boolean(items?.docs.length) && (
              <ProductionsList
                items={items.docs}
                showModal={showModalHandler}
                printSKULabel={printSKULabel}
                settings={settings}
              />
            )}
            {searchTxt && !Boolean(items?.docs.length) && !loading && (
              <Banner>
                <span>Items not found.</span>
              </Banner>
            )}
          </>
        )}
        <ScrollTop />
      </section>
      <ModalItem
        modalIsOpen={itemModalIsOpen}
        setModalIsOpen={setItemModalIsOpen}
        title={"Production"}
        mode={mode}
        item={item}
        setItem={setItem}
        submitHandler={submitHandler}
        selectedItemsType={selectedItemsType}
        settings={settings}
        handleGeneratePMT={handleGeneratePMT}
        user={user}
        handleDelete={handleDelete}
        onAfterCloseModal={onAfterCloseModal}
      />

      <ModalPreloader modalPreloaderIsOpen={isFetching || loading} />
    </>
  );
}
