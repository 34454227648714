import _ from "lodash";

export const generatePMTHandler = (item, settings) => {
  console.log("* generatePMTBarcode init");
  // console.log("- item: ", item);
  return new Promise(async (resolve, reject) => {
    const selectedModel = settings?.pretreatmentConfigures?.model;
    console.log("[generatePMTHandler] selectedModel: ", selectedModel);

    let color = item?._component?.color
      ? item._component.color
      : item?.color
      ? item.color
      : item.sku.substring(0, item.sku.indexOf("-"));
    console.log("[generatePMTHandler] color: ", color);
    let platen = "14x16";
    let size = { width: 14, height: 16 };
    if (item._graphics.length > 0) {
      platen = item._graphics[0]?.platen;
      size = item._graphics[0]?.size;
    }
    console.log("[generatePMTHandler]  platen: ", platen);
    console.log("[generatePMTHandler]  size: ", size);

    try {
      let result;
      if (selectedModel.includes("Schulze")) {
        const { speedColor } = settings?.pretreatmentConfigures;
        // console.log("- speedColor: ", speedColor)

        let speedDefault = 110;
        let speedTemp = _.find(speedColor, (item) => {
          // console.log('- item: ', item)
          let regex = new RegExp(color, "i");
          return regex.test(item.color) && item;
        });

        console.log("[generatePMTHandler] speedTemp: ", speedTemp);
        const speed = speedTemp?.speed ? speedTemp.speed : speedDefault;
        console.log("[generatePMTHandler] speed: ", speed);

        result = await generateSchulzePMTBarcode({ size, platen, speed });
      } else {
        const { sprayAmount } = settings?.pretreatmentConfigures;
        console.log("[generatePMTHandler] sprayAmount: ", sprayAmount);
        let defaultBarcodeValue = settings?.pretreatmentBarcode?.defaultValue
          ? settings?.pretreatmentBarcode?.defaultValue
          : "580014001600000100000000";
        console.log(
          "[generatePMTHandler:viper] defaultBarcodeValue: ",
          defaultBarcodeValue
        );
        let amountDefault = defaultBarcodeValue.substring(0, 4) || 5800;
        console.log("[generatePMTHandler] amountDefault: ", amountDefault);

        let amountTemp = _.find(sprayAmount, (item) => {
          // console.log('- item: ', item)
          let regex = new RegExp(color, "i");
          return regex.test(item.color) && item;
        });

        console.log("[generatePMTHandler] amountTemp: ", amountTemp);
        const amount = amountTemp?.amount ? amountTemp.amount : amountDefault;
        console.log("[generatePMTHandler] amount: ", amount);
        result = await generateViperPMTBarcode({ size, platen, amount });
      }
      // console.log('- result: ', result)
      result && resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

const generateSchulzePMTBarcode = ({ size, platen, speed }) => {
  console.log("* generateSchulzePMTBarcode init");
  return new Promise(async (resolve, reject) => {
    console.log("[generateSchulzePMTBarcode] size :", size);
    console.log("[generateSchulzePMTBarcode] platen :", platen);
    console.log("[generateSchulzePMTBarcode] speed :", speed);
    try {
      let pmtConstruct = {
        nozzle: "1111",
        sprayStart: "00",
        sprayEnd: "",
        speed: "",
        unit: "1",
        doubleSpray: "1",
        table: "0",
      };

      let width = size?.width ? size.width : 14;
      let height = size?.height ? size.height : 16;
      console.log("[generateSchulzePMTBarcode] height: ", height);
      console.log("[generateSchulzePMTBarcode] width(inch): ", width);
      const maxH = 40;
      const criteriaInchW = 9;
      // set sprayEnd
      let h = height + 3 > maxH ? maxH : height + 3;
      let sprayEnd = h < 10 ? `0${h}` : `${h}`;
      pmtConstruct.sprayEnd = sprayEnd;
      // set speed
      pmtConstruct.speed = `${speed}`;
      console.log(
        `- width(${width}) < criteriaInchW(${criteriaInchW}):  ${
          width < criteriaInchW
        }`
      );
      if (width < criteriaInchW) {
        pmtConstruct.nozzle = "0110";
      } else {
        pmtConstruct.nozzle = "1111";
      }
      console.log("- values: ", Object.values(pmtConstruct));
      let values = Object.values(pmtConstruct).join("");
      resolve({ pretreatmentBarcode: values });
    } catch (error) {
      reject(error);
    }
  });
};

function getViperSize(value) {
  console.log("* getViperSize init");
  console.log("[getViperSize] value: ", value);
  for (let index = 0; index < 4; index++) {
    if (value.length < 4) {
      value = value + "0";
    } else {
      return value;
    }
  }
}

const generateViperPMTBarcode = ({ size, platen, amount }) => {
  console.log("* generateViperPMTBarcode init");
  return new Promise(async (resolve, reject) => {
    console.log("[generateViperPMTBarcode] size :", size, typeof size);
    console.log("[generateViperPMTBarcode] platen :", platen);
    console.log("[generateViperPMTBarcode] amount :", amount);
    let sizeWidth = size?.width ? size.width : 14;
    let sizeHeight = size?.height ? size.height : 16;
    sizeWidth = sizeWidth.toString();
    sizeHeight = sizeHeight.toString();
    console.log(
      "[generateViperPMTBarcode] sizeWidth :",
      sizeWidth,
      typeof sizeWidth
    );
    console.log(
      "[generateViperPMTBarcode] sizeHeight :",
      sizeHeight,
      typeof sizeHeight
    );

    let platenArr = ["14", "16"];
    if (!_.isNil(platen) && _.includes("x")) platenArr = platen.split("x");
    let platenWidth = platenArr[0].toString(),
      platenHeight = platenArr[1].toString();
    console.log(
      "[generateViperPMTBarcode] platenWidth :",
      platenWidth,
      typeof platenWidth
    );
    console.log(
      "[generateViperPMTBarcode] platenHeight :",
      platenHeight,
      typeof platenHeight
    );

    let xWidth = getViperSize(
      sizeWidth ? sizeWidth : platenWidth && platenWidth
    );
    let yWidth = getViperSize(
      sizeHeight ? sizeHeight : platenHeight && platenHeight
    );

    console.log("[generateViperPMTBarcode] xWidth :", xWidth, typeof xWidth);
    console.log("[generateViperPMTBarcode] yWidth :", yWidth, typeof yWidth);

    try {
      let pmtConstruct = {
        amount,
        xWidth,
        yWidth,
        autoCenter: "0001",
        xOrigin: "0000",
        yOrigin: "0000",
      };

      let values = Object.values(pmtConstruct).join("");
      console.log(
        "[generateViperPMTBarcode] values:length ",
        values,
        values.length
      );
      resolve({ pretreatmentBarcode: values });
    } catch (error) {
      reject(error);
    }
  });
};
