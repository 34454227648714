import React from 'react'
import { toast, ToastContainer } from 'react-toastify'
// import Skeleton from 'react-loading-skeleton'
import { Title } from 'utils/Title'
import PageMainHeader from 'components/PageMainHeader'
import { AuthContext } from "contexts/AuthContext"
import { SettingsContext } from 'contexts/SettingsContext'
import PageMenuHeader from 'components/PageMenuHeader'
import { getAllShipStationOrders } from "utils/shipstation";
import _ from "lodash";
import Banner from '@leafygreen-ui/banner'
import Stepper from "@leafygreen-ui/stepper";
import Button from "@leafygreen-ui/button";
import Icon from "@leafygreen-ui/icon";
import ConfirmationModal from "@leafygreen-ui/confirmation-modal";
import { errorHandler } from 'utils/errorHandler'
import { findOrders, getLocalOrder, updateOrder } from 'utils/orders'
import { getBins, updateBin, assignBin } from 'utils/bins'
import { getItemDoc, submitItem } from 'utils/items'
import { getPackingItem, addPackingItem, updatePackingItem } from 'utils/packingItems'
import { useQuery, useQueryClient } from "react-query";
import { Console, Hook, Unhook } from 'console-feed';
import log from "utils/log";
import { GridLoader } from 'react-spinners'
import { useHistory } from 'react-router-dom'
// import { submitGraphic } from 'utils/graphics'
import socketIOClinet from "socket.io-client";

const socket = socketIOClinet(process.env.REACT_APP_SOCKET_ENDPOINT);

const title="Sync Orders"
let initialFnResult = {result: false, message: null};

const initialSteps = [
  {name: 'Fetch ShipStation orders', id: 1},
  {name: 'Import orders into Printflo', id: 2},
  {name: 'Create items', id: 3},
  {name: 'Create packing items', id: 4},
  {name: 'Assign Bins', id: 5},
  {name: 'Update order quantities', id: 6},
]

export default function SyncOrders() {
  const { settings } = React.useContext(SettingsContext);
  const { user } = React.useContext(AuthContext);
  const [currentStep, setCurrentStep] = React.useState(0)
  // const {isLoading, isError, error} = props
  const [msg, setMsg] = React.useState('Click to run sync orders')
  const [disableSyncBtn, setDisableSyncBtn] = React.useState(false);
  const [openConfirmSyncBanner, setOpenConfirmSyncBanner] = React.useState(false);
  const ssOrdersRef = React.useRef([]);
  const orderItemsRef = React.useRef([]);
  const [logs, setLogs] = React.useState([]);
  const consoleRef = React.useRef(null);
  const bannerVariantRef = React.useRef('info');
  const [steps, setSteps] = React.useState(initialSteps);
  const history = useHistory()
  const syncOrdersFlag = React.useRef("front");

  const {
    data: packingItems
  } = useQuery(["packingItems"], async () => await getPackingItem({condition: {isActive: true, source: 'shipstation'}}));

  const queryClient = useQueryClient()

  const onSyncOrderResultCallback = React.useCallback(() => {
    socket.on("on-sync-order-result", (result) => {
      console.log("on-sync-order-result", result);
      setDisableSyncBtn(false);
      let msg = result?.message;
      toast.info(msg, {
        position: "bottom-right",
        autoClose: false,
        onClose: () => history.push('/work-orders')
      });
    });
    // eslint-disable-next-line
  }, []);

  //onSyncOrderResultCallback
  React.useEffect(() => {
    onSyncOrderResultCallback();

    return () => {
      socket.removeAllListeners(["on-sync-order-result"]);
    };
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    Hook(
      window.console,
      (log) => setLogs((currLogs) => [...currLogs, log]),
      false
    )
    return () => Unhook(window.console)
  }, [])

  React.useEffect(() => {
    consoleRef.current?.scrollIntoView({behavior: 'smooth'})
  }, [logs])

  React.useEffect(() => {
    console.log('[ssOrdersRef hook] init');
    console.log('[ssOrdersRef hook] Number of ssOrdersRef: ', ssOrdersRef.current.length)
    if(Boolean(ssOrdersRef.current.length)) {
      const orderItems = getOrderItems()
      // console.info('[ssOrdersRef hook] orderItems: ', orderItems);
      orderItemsRef.current = orderItems;
      console.log('[ssOrdersRef hook] fetched orderItemsRef: ', orderItems.length);
    }
    // eslint-disable-next-line
  }, [ssOrdersRef.current])

  React.useEffect(() => {
    return () => {
      window.localStorage.removeItem('ss_orders');
    }
  }, [])

  React.useEffect(() => {
    if(settings) {
      if(settings?.binAssignmentType === 'hanger') {
        setSteps(() => {
          // let tempStep = _.filter(initialSteps, (s) => !_.includes(s.name, 'Bin'))
          // tempStep[1].name = tempStep[1].name + ' & Assign Bins';
          return [
            {name: 'Fetch ShipStation orders', id: 1},
            {name: 'Import orders into Printflo & Assign Bins', id: 2},
            {name: 'Create items', id: 3},
            {name: 'Create packing items', id: 4},
            {name: 'Update order quantities', id: 5},
          ]
        })
      }
    }
      
    return () => setSteps(initialSteps);
  }, [settings])

  const syncOrdersBackend = () => {
    console.log("* syncOrders init");
    console.log("- socket.connected: ", socket.connected);
    setOpenConfirmSyncBanner(false);
    if (socket.connected) {
      socket.emit("on-sync-order");
      log.info(`sync orders init at ${new Date()}`);
      setDisableSyncBtn(true);
      setMsg("Sync orders:backend init")
    } else {
      alert(
        "Sync orders can only be executed on stations that have a Printflo license assigned"
      );
    }
  };

  const syncOrders = async () => {
    console.log("* syncOrders init");
    console.time('Sync Orders Render Time');

    log.info(`sync orders init at ${new Date()}`);
    
    if(currentStep !== 0) setCurrentStep(0);
    setOpenConfirmSyncBanner(false);
    // Each fn's initial result
    let fetchShipStationOrdersReult = initialFnResult;
    let inActiveLocalOrdersResult = initialFnResult;
    let createLocalOrdersResult = initialFnResult;
    let createLocalItemResult = initialFnResult;
    let inActivatePackingItemsResult = initialFnResult;
    let createPackingItemsResult = initialFnResult;
    let assignBinsResult = initialFnResult;
    let updateOrderQtyResult = initialFnResult;

    // 1. get all shipstation orders
    try {
      console.time('[Step1 Render Time]')
      fetchShipStationOrdersReult = await fetchShipStationOrders()
      console.log('[syncOrders] fetchShipStationOrdersReult: ', fetchShipStationOrdersReult)
      console.timeEnd('[Step1 Render Time]')
    } catch (error) {
      console.error('[syncOrders] fetchShipStationOrders error: ', error)
      bannerVariantRef.current = 'danger'
      setMsg(error);
      return;
    }

    // 2. import local order into Printflo
    // 2.1 in-activate local orders
    try {
      setCurrentStep(1)
      inActiveLocalOrdersResult = await inActiveLocalOrders()
      console.log("- inActiveLocalOrdersResult: ", inActiveLocalOrdersResult)
      setMsg(inActiveLocalOrdersResult.message)
    } catch (error) {
      console.log('- inActiveLocalOrders error: ', error)
      const retVal = errorHandler(error)
      bannerVariantRef.current ='danger';
      setMsg(retVal)
      return
    }

    // 2.2 create orders into Printflo if a ShipStation order not exists
    try {
      createLocalOrdersResult = await createLocalOrders();
      console.log("- createLocalOrdersResult: ", createLocalOrdersResult)
      setMsg(createLocalOrdersResult.message)
    } catch (error) {
      console.error('-[createLocalOrdersResult] error: ', error)
      const retVal = errorHandler(error)
      bannerVariantRef.current ='danger';
      setMsg(retVal)
      return
    }

    // 3 create items
    try {
      setCurrentStep(2);
      createLocalItemResult = await createItems()
      console.log('- createLocalItemResult: ', createLocalItemResult)
      setMsg(createLocalItemResult.message)

    } catch (error) {
      console.error(error);
      setMsg(error)

      return
    }
    

    // 4. create packing items
    try {
      setCurrentStep(3);
      bannerVariantRef.current ='info';
      setMsg('Creating packing items init')
      inActivatePackingItemsResult = await inActivatePackingItems()
      console.log('- inActivatePackingItemsResult: ', inActivatePackingItemsResult)
      createPackingItemsResult = await createPackingItems()
      console.log('- createPackingItemsResult: ', createPackingItemsResult)
      setMsg(createLocalItemResult.message)
    } catch (error) {
      console.error(error);
      bannerVariantRef.current ='danger';
      setMsg(error)
      return
    }

    // 5. assign bins
    if(settings?.binAssignmentType !=='hanger') {
      try {
        setCurrentStep(4);
        assignBinsResult = await assignBins()
        console.log('- assignBinsResult: ', assignBinsResult)
        setMsg(assignBinsResult.message)
      } catch (error) {
        console.error(error);
        bannerVariantRef.current ='danger';
        setMsg(error)
        return
      }

    }

    // 6. update order qty
    try {
      if(settings?.binAssignmentType !=='hanger') {
        setCurrentStep(4);
      } else {
        setCurrentStep(5);
      }
      updateOrderQtyResult = await updateOrderQty()
      console.log('- updateOrderQtyResult: ', updateOrderQtyResult)
      setMsg(updateOrderQtyResult.message);

      if(updateOrderQtyResult.result) {
        setCurrentStep(6);
        window.localStorage.removeItem('ss_orders');
        setMsg('Done syncing orders')
        toast.info('Click to move work orders', {
          autoClose: false,
          position: 'bottom-right',
          onClose: () => history.push('/work-orders')
        })
      }
      
    } catch (error) {
      console.error(error);
      bannerVariantRef.current ='danger';
      setMsg(error)
      setDisableSyncBtn(false)

      return
    }
    console.timeEnd('Sync Orders Render Time')
  };

  function fetchShipStationOrders() {
    return new Promise(async (resolve, reject) => {
      try {
        setDisableSyncBtn(true)
        bannerVariantRef.current ='info';
        setMsg('fetchShipStationOrders init')
        let fnResult = initialFnResult;
        let orderStatus = settings?.orderStatus
        const stored_ss_orders = window.localStorage.getItem('ss_orders')
        // console.info('-stored_ss_orders: ', stored_ss_orders)
        if(_.isNil(stored_ss_orders)) {
          console.time('Request ShipStation orders render time')
          setMsg(`Calling ShipStation orders for ${orderStatus}, waiting...`);
          console.log(`[syncOrders] Calling ShipStation's orders for ${orderStatus}`);
          let ssOrdersTemp1 = await getAllShipStationOrders({orderStatus});
          console.log(`[syncOrders] ssOrdersTemp1: ${ssOrdersTemp1.length}`);
          ssOrdersRef.current = ssOrdersTemp1
          if (settings?.useAwaitingPayment) {
            let ssOrdersTemp2 = await getAllShipStationOrders("awaiting_payment");
            console.log(`[syncOrders] ssOrdersTemp2: ${ssOrdersTemp2.length}`);
            if (Boolean(ssOrdersTemp2.length)) {
              ssOrdersRef.current = [ssOrdersRef.current, ...ssOrdersTemp2]
            }
          }
          console.log('[syncOrders] ssOrdersRef count: ', ssOrdersRef.current.length);
          window.localStorage.setItem('ss_orders', JSON.stringify(ssOrdersRef.current));
          console.timeEnd('Request ShipStation orders render time')
          fnResult={result: true, message: "Completed fetching ShipStation orders"}
          setDisableSyncBtn(false)

          resolve(fnResult)
        } else {
          ssOrdersRef.current = JSON.parse(stored_ss_orders)
          fnResult={result: true, message: "Fetched ShipStation orders from localStorage"}
          setDisableSyncBtn(false)
          resolve(fnResult)
        }
      } catch (error) {
        console.error('-[syncOrders] ssOrder error: ', error)
        const retVal = errorHandler(error);
        setDisableSyncBtn(false)
        reject(retVal)
      }
    })
  }

  function inActiveLocalOrders() {
    console.log("* In-activating local orders init")
    return new Promise(async (resolve, reject) => {
      setDisableSyncBtn(true);
      bannerVariantRef.current ='info';
      setMsg('In-activating local orders init')
      const stored_ss_orders = window.localStorage.getItem('ss_orders');
      // console.info('[inActiveLocalOrders] stored_ss_orders: ', stored_ss_orders)
      if(_.isNil(stored_ss_orders)) {
        bannerVariantRef.current = 'danger';
        setDisableSyncBtn(false);
        setMsg('Required fetching ShipStation orders.');
        return
      }
      console.time('[inActiveLocalOrders] render time');
      const ssOrders = ssOrdersRef.current;
      let fnResult = initialFnResult;
      
      let orderStatusFilter = {
        orderStatus: { $in: ["awaiting_shipment"] },
        source: 'shipstation'
      };
  
      if (settings?.useAwaitingPayment) {
        orderStatusFilter = {
          ...orderStatusFilter,
          orderStatus: { $in: ["awaiting_shipment", "awaiting_payment"] },
        };
      }
      try {
        const localOrders = await findOrders(orderStatusFilter);
        console.log('[inActiveLocalOrders] localOrders count: ', localOrders.length);

        let deletedCount = 0;
        if(Boolean(localOrders.length)) {
          for (let i = localOrders.length - 1; 0 <= i; i--) {
            
            const localOrder = localOrders[i];
            const { orderId, orderNumber, bin } = localOrder;
            // console.log(`- ${i}/${localOrders.length}, orderId: ${orderId}, bin: ${bin}`);
            
            const ssOrderIndex = _.findIndex(ssOrders, { orderId });
            if (ssOrderIndex === -1) {
              console.log(`[inActiveLocalOrders] ${deletedCount}`)
              console.log(`[inActiveLocalOrders] orderId: ${orderId}`)
              console.log(`[inActiveLocalOrders] orderNumber: ${orderNumber}`)
              console.log(`[inActiveLocalOrders] bin: ${bin}`);
              console.log(`[inActiveLocalOrders] orderId ${orderId} in-activating`);
              
              await updateOrder({
                condition: { orderId },
                update: { $set: { orderStatus: null, modifiedAt: new Date() } }
              })
              
              if (!_.isNil(bin)) {
                await updateBin({
                  condition: { binCode: bin },
                  update: { $set: { isActive: false } }
                });
                console.log(`[inActiveLocalOrders] Bin code ${bin} in-activated`);
              }
              deletedCount++;
              
            }

            if (i === 0){
              console.log(`[inActiveLocalOrders] Number of in-activated localOrders : ${deletedCount}`
              );
              fnResult = {
                result: true, 
                message: 'Completed in-activating local orders which status might be changed in ShipStation instead of Printflo'
              }
              setMsg(fnResult.message)
              resolve(fnResult)
            }
          }
        } else {
          fnResult = {
            result: true, 
            message: 'Local orders not exist'
          }
          setMsg(fnResult.message)
          setDisableSyncBtn(false)

          resolve(fnResult)
        }
      } catch (error) {
        setDisableSyncBtn(false)
        reject(error)
      }
      console.timeEnd('[inActiveLocalOrders] render time');

    })
    
  }

  function createLocalOrders() {
    console.log('* createLocalOrders init')

    return new Promise(async (resolve, reject) => {
      setDisableSyncBtn(true)
      bannerVariantRef.current ='info';
      setMsg('Creating local orders init')

      const ssOrders = ssOrdersRef.current;
      let fnResult = initialFnResult;
      try {
        console.time('[createLocalOrders] render time');
        if(Boolean(ssOrders.length)) {
          const len = ssOrders.length - 1;
          for (let i = len; i >= 0; i--) {
            const ssOrder = ssOrders[i];
            // console.info(`[createLocalOrders] ssOrder: ${i}/${len} `, ssOrder)
            const {
              orderId,
              orderNumber,
              orderStatus,
              carrierCode,
              serviceCode,
              packageCode,
              tagIds,
              orderDate,
            } = ssOrder;
  
            const localOrder = await getLocalOrder(orderId);
            // console.log(`[createLocalOrders] ${i + 1}/${len + 1} orderId: ${orderId}`)
            // console.info(`[createLocalOrders] localOrder: `, _.isObject(localOrder))
            // console.log(`[createLocalOrders] orderNumber: ${orderNumber}`);
  
            if (!_.isObject(localOrder)) {
              console.log(`[createLocalOrders] ${i + 1}/${len + 1} orderId: ${orderId} creating..`)
              let payload = {
                orderId,
                orderNumber,
                orderStatus,
                carrierCode,
                serviceCode,
                packageCode,
                tagIds,
                orderDate,
                createdAt: new Date(),
                source: "shipstation",
              };
              // console.info('[createLocalOrders] payload for createOrder: ', payload)
  
              await updateOrder({
                condition: {orderId},
                update: payload
              })
                .then(async (result) => {
                  console.log(`[createLocalOrders] orderId ${result.orderId} was created`);
                  if(settings?.binAssignmentType === 'hanger') {
                    console.log('[createLocalOrders] assign bins for binAssignmentType is hanger')
                    let assignedBin = await assignBin(orderId, 1)
                    console.log(`[createLocalOrders] assignedBin: ${assignedBin?.binCode}`)

                  }
                })
                .catch((error) => {
                  console.error(`[createLocalOrders] createOrder error:`,  error);
                  const retVal = errorHandler(error)
                  setMsg(retVal)
                  return
                });
            } else {
              console.log(`[createLocalOrders] ${i + 1}/${len + 1} orderId: ${orderId} exists then checking..`)

              if (localOrder?.orderStatus !== ssOrder?.orderStatus) {
                console.log(
                  "[createLocalOrders] Case of orderStatus differnt between localOrder and S/S order"
                );
                console.log(`[createLocalOrders] local: ${localOrder.orderStatus}, S/S: ${ssOrder.orderStatus}`)
                let payload = {
                  orderStatus: ssOrder.orderStatus,
                  modifiedAt: new Date(),
                };
                console.log(`[createLocalOrders] updating local order orderId: ${orderId}`);
                await updateOrder({condition: { orderId }, update: { $set: payload }});
  
                //remove bin if orderStatus is not awaiting_shipment/awaiting_payment
                // order list filter options(array)
                if (!_.includes(ssOrder.orderStatus, "awaiting")) {
                  if (!_.isNil(localOrder.bin)) {
                    await updateBin({
                      condition: { binCode: localOrder.bin },
                      update: {
                        $set: { isActive: false, modifiedAt: new Date() },
                      }
                    });
                  }
                }
  
              }
            }
  
            if (i === 0) {
              // console.log("- end creating local orders");
              fnResult = {
                result: true, 
                message: 'Completed creating local orders'
              }
              bannerVariantRef.current = 'info'
              setDisableSyncBtn(false)
              resolve(fnResult);
            }
          }
        } else {
          console.log('[createLocalOrders] Not found ShipStation orders')
          setMsg('Not found ShipStation orders')
          setDisableSyncBtn(false)

        }
      } catch (error) {
        console.error("[createLocalOrders] error: ", error);
        const retVal = errorHandler(error)
        setDisableSyncBtn(false)
        reject(retVal);
      }
    })
  }

  function getOrderItems() {
    try {
      const ssOrders = ssOrdersRef.current;
      let orderItemsTemp = _.chain(ssOrders)
        .each(async (order) => {
          let sequence = 1;
          let filteredOrderItems = _.filter(order.items, { adjustment: false });
          let count = filteredOrderItems.length;

          return filteredOrderItems.map(async (item) => {
            item.checkedOutQty = 0;
            item.count = count;
            item.sequence = sequence;
            item.orderId = order.orderId;
            item.orderNumber = order.orderNumber;
            sequence += 1;
            return item;
          });
        })
        .map((o) => o.items)
        .flatten()
        .filter({ adjustment: false })
        .value();

      return orderItemsTemp;
    } catch (error) {
      console.error("- getOrderItems error:", error);
      const retVal = errorHandler(error)
      return retVal;
    }
  };

  function createItems () {
    return new Promise(async (resolve, reject) => {
      try {
        console.log(`* createItems init`);
        setDisableSyncBtn(true);
        bannerVariantRef.current ='info';
        setMsg('Creating items init')
        if(!Boolean(orderItemsRef.current.length)) {
          bannerVariantRef.current = 'danger';
          setMsg('Not found order items!, required fetching ShipStation orders');
          setDisableSyncBtn(false);
          return
        }
        const orderItems = orderItemsRef.current;
        let fnResult = initialFnResult;
        let useProductDescription = false, usePrimarySKU = false, primarySKUSeparator="-";
        if(settings) {
          // console.info('[createItems] settings: ', settings)
          useProductDescription = settings?.useProductDescription;
          usePrimarySKU = settings?.usePrimarySKU;
          if(!_.isNil(settings?.primarySKUSeparator)) {
            primarySKUSeparator = settings.primarySKUSeparator;
          } 
        }
        console.log(`[createItems] useProductDescription: ${useProductDescription}`)
        console.log(`[createItems] usePrimarySKU: ${usePrimarySKU}`)
        console.log(`[createItems] primarySKUSeparator: ${primarySKUSeparator}`);

        if(Boolean(orderItems.length)) {
          for (let i = orderItems.length - 1; i >= 0; i--) {
            // console.log(`[createItems] ${i}/${orderItems.length}`)
  
            const orderItem = orderItems[i];
            // const orderId = orderItem.orderId;

            let sku = orderItem.sku;
            // let upc = orderItem.upc;

            if (usePrimarySKU) {
              if (_.includes(sku, primarySKUSeparator)) {
                const indexOfDash = _.indexOf(sku, primarySKUSeparator);
                sku = sku.substring(0, indexOfDash);
              }
            }
  
            if (sku && !_.isEmpty(sku)) {
              sku = sku.trim();
              // console.log('[createItems] sku: ', sku)
              const localItem = await getItemDoc(sku);
              console.info(`[createItem] localItem: `, localItem)
              
              let itemPayload;
              // if not exist item doc in collection then create it.
              if (!_.isObject(localItem)) {
                console.log(`[createItems] ${i}/${orderItems.length} ${sku} not exists then creating...`)
                itemPayload = {
                  sku,
                  imageUrl: orderItem.imageUrl,
                  type: "production",
                  createdAt: new Date()
                }
                if(!useProductDescription) {
                  itemPayload['description'] = orderItem.name;
                }
                console.info('[createItems] itemPayload: ', itemPayload)
                await submitItem({
                  condition: {sku},
                  update: itemPayload
                })
                  .then(async () => {
                    console.log(`[createItems] ${sku} was created!`);
                    // get custom artwork link from item options: bluridge
                    /*
                    if(!settings?.useItemOptions && Boolean(settings?.itemOptionKeys.length)) {
                      const key = settings?.itemOptionKeys[0];
                      const itemOption = _.find(orderItem?.options, {name: key});
                      console.log("[createItems] itemOption: ", itemOption);
                      
                      // /save artwork filename from desgin in product_option
                      if(itemOption?.value && itemOption?.value.includes("http")) {
                        let graphicPosition = "Front";
                        let updatePayload = {
                          sku,
                          graphicPosition
                        }
                        updatePayload["graphicFileName"] = itemOption.value;
                        await submitGraphic({
                          condition: {
                            sku,
                            graphicPosition
                          },
                          update: updatePayload,
                        })
                      }
                    }
                    */
                  })
                  .catch((error) => {
                    console.error("[createItems] error: ", error);
                    reject(error);
                  });
              }
              // localItem exists
              else 
              {
                // check local item has childSKUs then create child item doc
                console.log(`[createItems] ${i}/${orderItems.length} ${sku} exists then checking...`)
                if (localItem?.childSKUs && _.isArray(localItem?.childSKUs) && Boolean(localItem?.childSKUs.length)) {
                  console.log('[createItems] case of childSKUs exists: run checking a child sku exists')
                  // check existence of sql items
                  localItem.childSKUs.forEach(async (childSKU) => {
                    // check existence of local item
                    const childLocalItem = await getItemDoc(childSKU);
                    console.log(`[createItems] childLocalItem sku: ${childLocalItem.sku}`);
                    if (!_.isObject(childLocalItem)) {
                      console.log('[createItems] case of a child sku not exists then create it')
  
                      itemPayload = {
                        sku: childSKU,
                        imageUrl: orderItem.imageUrl,
                        type: "production",
                        createdAt: new Date(),
                      }
                      if(!useProductDescription) {
                        itemPayload['description'] = orderItem.name;
                      }
                      console.info('[createItems] itemPayload: ', itemPayload)

                      await submitItem({
                        condition: {sku: childSKU},
                        update: itemPayload
                      })
                        .then(() => {
                          console.log(`[createItems] (childSKU) ${childSKU} was created`);
                        })
                        .catch((error) => {
                          console.error(`[createItems] ${childSKU} create error`, error);
                          reject(error);
                        });
                    } 
                    // childLocalItem exists but try to update if some data exist
                    else 
                    {
                      if (
                        _.isNil(childLocalItem.description) ||
                        _.isEmpty(childLocalItem.description) ||
                        _.isNil(childLocalItem.imageUrl) ||
                        _.isEmpty(childLocalItem.imageUrl) ||
                        _.isNil(childLocalItem.type) ||
                        _.isEmpty(childLocalItem.type)
                      ) {
                        if(!useProductDescription) {
                          childLocalItem.description = orderItem.name;
                        }
                        childLocalItem.imageUrl = orderItem.imageUrl;
                        childLocalItem.type = "production";
                        childLocalItem.modifiedAt = new Date();
                        console.info('[createItems] childLocalItem for updating:  ', childLocalItem)
                        await submitItem({
                          condition: {sku: childSKU},
                          update: childLocalItem
                        })
                          .then(() => {
                            console.log(`[createItems] childLocalItem ${childLocalItem.sku} updated`);
                          })
                          .catch((error) => {
                            console.log(`[createItems] childLocalItem updated error`, error);
                            setMsg(error)
                            return
                          });
                      }
                    }
                  });
                }
  
                // update localItem data if some data are missing
                if (
                  _.isNil(localItem.description) ||
                  _.isEmpty(localItem.description) ||
                  _.isNil(localItem.imageUrl) ||
                  _.isEmpty(localItem.imageUrl) ||
                  _.isNil(localItem.type) ||
                  _.isEmpty(localItem.type)
                ) {
                  if(!useProductDescription) {
                    localItem.description = orderItem.name;
                  }
                  localItem.imageUrl = orderItem.imageUrl;
                  localItem.type = "production";
                  localItem.modifiedAt = new Date();
                  console.info('[createItems] localItem for updating:  ', localItem)
                  
                  await submitItem({
                    condition: {sku: localItem.sku},
                    update: localItem
                  })
                    .then(async () => {
                      console.log(`[createItems] localItem updated ${localItem.sku}`);
                    })
                    .catch((error) => {
                      console.log(`[createItems] localItem updated error`, { message: error });
                    });
                };

                /* get custom artwork link from item options: bluridge
                console.info("[createItems] settings?.useItemOptions: ", settings?.useItemOptions)
                console.info("[createItems] settings?.itemOptionKeys: ", settings?.itemOptionKeys)
                if(!settings?.useItemOptions && Boolean(settings?.itemOptionKeys.length)) {
                  const key = settings?.itemOptionKeys[0];
                  const itemOption = _.find(orderItem?.options, {name: key});
                  console.info("[createItems] itemOption: ", itemOption);
                  
                  // /save artwork filename from desgin in item option
                  if(itemOption?.value && itemOption?.value.includes("http")) {
                    await saveCustomGraphicUrl({sku, itemOption})
                  }
                }
                */
              }
              // case of found item in local items
              
            }
            // end temp
  
            if (i === 0) {
              // console.log("- done creating items");
              fnResult = {result: true, message: "Completed creating items"}
              setDisableSyncBtn(false);
              resolve(fnResult);
            }
            // } // end test if
          } // end for
        }
      } catch (error) {
        console.error("[createItem] error", { message: error });
        setDisableSyncBtn(false);

        reject(error);
      }
    });
  };

  function inActivatePackingItems() {
    console.log(`* inActivatePackingItems init`);
    return new Promise(async (resolve, reject) => {
      // delete a packingItem that not exists in orderItems by orderId;
      try {
        setDisableSyncBtn(true);
        bannerVariantRef.current = 'info';
        setMsg('In-activating packing items init')
        console.info('[inActivatePackingItems] orderItemsRef.current: ', orderItemsRef.current, typeof orderItemsRef.current)
        if(!Boolean(orderItemsRef.current.length)) {
          bannerVariantRef.current = 'danger';
          setDisableSyncBtn(false)
          setMsg('Not found order items, required fetching ShipStation orders')  
          return
        }
        const orderItems = orderItemsRef.current;
        let fnResult = initialFnResult;
       
        let i = 0;
        let len = packingItems.length;

        async function loop() {
          // console.log(`[inActivatePackingItems] ${i}/${len}`)
          const packingItem = packingItems[i];
          const { orderId } = packingItem;
          // const binCode = packingItem.bin;
          const isPackingItem = _.findIndex(orderItems, { orderId });
          // console.log(`[inActivatePackingItems] isPackingItem(index): ${isPackingItem}`)
          if (isPackingItem === -1) {
            console.log(`[inActivatePackingItems] ${i}/${len} case of packing item not exists then in-activating: orderId: ${orderId}`)

            await updatePackingItem({
              condition: { id: packingItem._id },
              update: {
                $set: {
                  isActive: false,
                  modifiedAt: new Date(),
                  shippedBy: "admin",
                },
              }
            })
              .then(() => {
                console.log(
                  `[inActivatePackingItems] in-activated packing item: orderId; ${orderId}, sku; ${packingItem.sku}`
                );
              })
              .catch((error) => {
                console.error(`[inActivatePackingItems] loop in-activating packing item error`, error);
                const retVal = errorHandler(error)
                setMsg(retVal)
                return
              });
          } else {
            console.log(`[inActivatePackingItems] ${i}/${len} case of packing item exists orderId: ${orderId}`)
          }
          i = i + 1;
          if (i < len) {
            loop();
          } else {
            console.log("[inActivatePackingItems] loop end");
            fnResult = {result: true, message: 'Completed in-activating packing items'}
            setDisableSyncBtn(false);
            resolve(fnResult);
          }
        } // end loop

        if (i < len) {
          console.log('[inActivatePackingItems] loop run')
          loop();
        } else {
          fnResult = {result: true, message: 'Packing items not exists'}
          setDisableSyncBtn(false);
          resolve(fnResult);
        }
      } catch (error) {
        console.error(`[inActivatePackingItems] error`, error);
        const retVal = errorHandler(error);
        setDisableSyncBtn(false);
        reject(retVal);
      }
    });
  }

  function createPackingItems() {
    console.log("* createPackingItems init");
    return new Promise(async (resolve, reject) => {
      setDisableSyncBtn(true);

      bannerVariantRef.current = 'info'
      setMsg("Creating packing items init")
      const orderItems = orderItemsRef.current;
      let fnResult = initialFnResult;

      let usePretreatmentBarcode = false;
      let pretreatmentConfigures;
      let aws;
      let useItemOptions = false;
      let itemOptionKeys;
      let usePrimarySKU = false;
      let primarySKUSeparator = "-";

      if(settings) {
        // console.info('-[createPackingItems] settings: ', settings)
        usePretreatmentBarcode = settings.usePretreatmentBarcode;
        pretreatmentConfigures = settings?.pretreatmentConfigures;
        aws = settings?.aws;
        useItemOptions = settings?.useItemOptions;
        itemOptionKeys = settings?.itemOptionKeys;
        usePrimarySKU = settings?.usePrimarySKU;
        if(!_.isNil(settings?.primarySKUSeparator)) {
          primarySKUSeparator = settings.primarySKUSeparator;
        }
      }
      console.info(`[createPackingItems]: usePretreatmentBarcode: ${usePretreatmentBarcode}`)
      console.info(`[createPackingItems]: pretreatmentConfigures: `, pretreatmentConfigures)
      console.info(`[createPackingItems]: aws: `, aws)
      console.info(`[createPackingItems]: useItemOptions: `, useItemOptions)
      console.info(`[createPackingItems]: itemOptionKeys: `, itemOptionKeys)
      console.info(`[createPackingItems]: usePrimarySKU: ${usePrimarySKU}, primarySKUSeparator: ${primarySKUSeparator}`);
      
      let i = 0;
      const len = orderItems.length;
      
      console.log(`[createPackingItems] loop run`)
      async function loop() {
        console.log(`[createPackingItems] ${i}/${orderItems.length}`)

        const orderItem = orderItems[i];
        const { orderId, orderItemId, quantity } = orderItem;
        let { sku, upc } = orderItem;
        console.log(`[createPackingItems] sku: ${sku}, upc: ${upc}`);

        let itemOptions = [];
        itemOptions = orderItem?.options;
        console.info('[createPackingItems] itemOptions: ', itemOptions)
        if (usePrimarySKU) {
          if (_.includes(sku, primarySKUSeparator)) {
            const indexOfDash = _.indexOf(sku, primarySKUSeparator);
            sku = sku.substring(0, indexOfDash);
          }
        }

        if (!orderItem?.adjustment && !_.isEmpty(sku) && !_.isNil(sku)) {
          sku = sku.trim();
          
          const tempPackingItems = await getPackingItem({condition: {
            orderId,
            sku,
            orderItemId: `${orderItemId}`,
          }});
          console.log(`[createPackingItems] orderId: ${orderId}`)
          console.log(`[createPackingItems] orderItemId: ${orderItemId}`)
          console.log(`[createPackingItems] sku: ${sku}`)
          console.log("[createPackingItems] tempPackingItems:", tempPackingItems.length);
          let packingItem;
          if(Boolean(tempPackingItems.length)) packingItem = tempPackingItems[0];
          // console.info('[createPackingItems] packingItem: ', packingItem)
          
          if (_.isNil(packingItem)) {
            console.log('[createPackingItems] case of packingItem not exist')
            await getItemDoc(sku)
              .then(async (itemInfo) => {
                if (itemInfo) {
                  //checking child sku
                  let hasChildSKUs = false;
                  let tempChildSKUs = itemInfo?.childSKUs;
                  if (tempChildSKUs && tempChildSKUs.length > 0) {
                    let firstChild = tempChildSKUs[0];
                    if (firstChild.length > 0) hasChildSKUs = true;
                  }

                  // bundle item
                  if (hasChildSKUs) {
                    console.log(`[createPackingItems] ${sku} is bundle parent`);
                    // update hasBundleItem true and quantity in order doc
                    await updateOrder({
                      condition: { orderId },
                      update: { hasBundleItem: true }
                    });

                    itemInfo.childSKUs.forEach(async (childSKU) => {
                      console.log(`[createPackingItems] childSKU: ${childSKU}`);
                      let childSkuPackingItem = await getPackingItem({
                        condition: {
                          orderId,
                          sku: childSKU,
                          isBundle: true,
                          parentSKU: itemInfo.sku,
                          orderItemId: `${orderItemId}`,
                        }
                    });
                      // console.info(`[createPackingItems] childSkuPackingItem:`, childSkuPackingItem);
                      // console.info(`[createPackingItems] childSkuPackingItem typeof:`, typeof childSkuPackingItem);

                      if (!Boolean(childSkuPackingItem.length)) {
                        console.log(`- ${orderId}, childSKU: ${childSKU} not exits in PackingItem then creating...`);
                        await getItemDoc(childSKU)
                          .then(async (item) => {
                            if (item) {
                              await addPackingItem({
                                update: {
                                  count: orderItem.count,
                                  sku: childSKU,
                                  isBundle: true,
                                  isActive: true,
                                  sequence: orderItem.sequence,
                                  orderId,
                                  orderNumber: orderItem.orderNumber,
                                  orderItemId,
                                  parentSKU: orderItem.sku,
                                  quantity,
                                  createdAt: new Date(),
                                  source: 'shipstation'
                                }
                              })
                                .then((result) => {
                                  console.log(
                                    `[createPackingItems] ${childSKU}(bundle packing item) was created`
                                  );
                                  // createdPackingItemsRef.current = [...createdPackingItemsRef.current, result]
                                })
                                .catch((error) => {
                                  console.error(
                                    `[createPackingItems]  ${childSKU}(bundle packing item) create error:`, error
                                  )
                                })
                            } // end of item
                          })
                          .catch((error) => {
                            console.error(
                              `[createPackingItems] ${childSKU}(childSKU) getItemDoc error: `,error
                            );
                            const retVal = errorHandler(error)
                            setMsg(retVal)
                            return
                          });
                      } else {
                        console.log(
                          `[createPackingItems] ${orderId}, childSKU: ${childSKU} exits in PackingItem`
                        );
                      }
                    });
                  }
                  //single item
                  else
                  {
                    // add an key of options for aspen-company
                    let options = [];

                    if (useItemOptions) {
                      console.log('[createPackingItems] case of useItemOptions')
                      if (Boolean(itemOptions.length)) {
                        itemOptions.forEach((option) => {
                          console.info('[createPackingItems] option: ', option)
                          let foundResult = _.find(itemOptionKeys, (key) => {
                            console.info(`[createPackingItems] key: ${key}, option.name: ${option.name}`)
                            return option.name === key && option;
                          });
                          console.info('[createPackingItems] foundResult: ', foundResult)
                          if (foundResult) options.push(option);
                        });
                      }
                    }

                    if(Boolean(options.length)) console.info('[createPackingItems] options: ', options)

                    await addPackingItem({
                      update: {
                        count: orderItem.count,
                        sku,
                        isActive: true,
                        sequence: orderItem.sequence,
                        orderId,
                        orderNumber: orderItem.orderNumber,
                        orderItemId,
                        quantity,
                        createdAt: new Date(),
                        options,
                        source: 'shipstation'
                      }
                    })
                      .then((result) => {
                        console.log(
                          `[createPackingItems] ${sku}(single) was created`
                        );
                        // createdPackingItemsRef.current = [...createdPackingItemsRef.current, result]

                      })
                      .catch((error) => {
                        console.log("[createPackingItems] creating packing item error", error);
                        const retVal = errorHandler(error)
                        setMsg(retVal)
                        return
                      });
                  }

                } else {
                  console.log('[createPackingItems] case of itemInfo not exist, skip creating a packing item!!!')
                }// end of itemInfo
              })
              .catch((error) => {
                console.error(`[createPackingItems] getItemDoc(${sku}) error`, error);
                const retVal = errorHandler(error)
                setDisableSyncBtn(false);

                reject(retVal);
              });
          } // end of is nill of packingItem
          
        }
        else 
        {
          console.log('[createPackingItems] case of adjustment & sku is null/empty')
        }
        
        i = i + 1;
        if (i < len) {
          loop();
        } else {
          console.log(`[createPackingItems] loop end: ${i}`);
          setTimeout(() => {
            fnResult = {result: true, message: "Completed creating packing items"}
            resolve(fnResult);
            setDisableSyncBtn(false);

          }, 1000);
        }
      } //end loop

      if (i < len) loop();
    });
  }

  function assignBins() {
    console.log("* assignBins init");
    return new Promise(async (resolve, reject) => {
      try {
        setDisableSyncBtn(true);
        let fnResult = initialFnResult;
        
        //
        const binsAll = await getBins({condition: {}})
        console.info('[assignBins] binsAll: ', binsAll)
        if(binsAll.length === 0) {
          fnResult = {result: true, message: "Bins not exists"}
          setDisableSyncBtn(false);
          resolve(fnResult);
          return
        }

        bannerVariantRef.current ='info';
        setMsg('Assigning Bin init')
        console.log("[assignBins] retrieving packing items");
        let tempPackingItems = [];
        const results = await getPackingItem({condition: {isActive: true, source: 'shipstation'}});
        console.info("[assignBins] getPackingItem results: ", results)
        tempPackingItems = _.cloneDeep(results);
        queryClient.setQueryData(["packingItems"], tempPackingItems);

        let binAssignmentType = "order";
        if(settings) {
          // console.info('[assignBins] settings: ', settings)
          binAssignmentType = settings?.binAssignmentType;
        }
        console.log("[assignBins] binAssignmentType", binAssignmentType);
        const orderIdGroup = _.groupBy(tempPackingItems, "orderId");
        // const orderIdGroup = _.groupBy(packingItems, "orderId");
        // console.log("[assignBins] orderIdGroup", orderIdGroup);
        let countOforderIdGroup = Object.keys(orderIdGroup).length;

        console.log(`[assignBins] count of orderId group: ${countOforderIdGroup}`);

        let i = 0;
        console.log('[assignBins] loop orderIdGroup run')
        for (let orderId in orderIdGroup) {
          console.log(`[assignBins] ${i}/${countOforderIdGroup} orderId: ${orderId}`);
          const order = orderIdGroup[orderId];
          // console.log("[assignBins] order: ", order);
          const orderNumber = order[0].orderNumber;
          const countOfOrderItem = parseInt(order.length);
          const sumOrderQty = parseInt(_.sumBy(order, "quantity"));

          let binCode;
          console.log(`[assignBins] countOfOrderItem: ${countOfOrderItem}, sumOrderQty: ${sumOrderQty}`)
          try {
            const localOrder = await getLocalOrder(orderId)
            if (!_.isNil(localOrder.bin) && !_.isEmpty(localOrder.bin)) {
              binCode = localOrder.bin;
            }
            
          } catch (error) {
            console.log('[assignBins] getLocalOrder error: ', error)
            const retVal = errorHandler(error)
            setMsg(retVal)            
          }

          console.log('[assignBins] binCode: ', binCode)

          if (binCode) {
            console.log(`[assignBins] ${binCode} already assigned then checking the binCode is active`);
            const binDoc = await getBins({
              condition: {
                binCode,
                isActive: true,
              }
            });
            console.info(`[assignBins] binDoc: `, binDoc);
            if (_.isNil(binDoc)) {
              console.log(`[assignBins] case of binDoc is null then activate`);

              await updateBin({
                condition: { binCode },
                update: { $set: { isActive: true, modifiedAt: new Date() } }
              });
            }
          }
          
          if (_.isNil(binCode)) {
            let assignedBin;
            let volumn = 1;
             if (countOfOrderItem > 1 || sumOrderQty > 1) {
              console.log(`[assignBins] case of multiple order items orderId: ${orderId}, orderNumber: ${orderNumber}`);
              if (binAssignmentType === "capacity") {
                volumn = _.sumBy(order, (o) => {
                  // console.log(o.orderId, o.quantity, o._item.dimensionalUnit);
                  let dimensionalUnit = 1;
                  if (_.has(o, "_item")) {
                    if (!_.isNil(o._item.dimensionalUnit)) {
                      dimensionalUnit = o._item.dimensionalUnit;
                    }
                  }
                  return o.quantity * dimensionalUnit;
                });
              } 
              console.log("[assignBins] volumn ", volumn);
              try {
                assignedBin = await assignBin(orderId, volumn)
                console.log("[assignBins] assignedBin ", assignedBin);
                
              } catch (error) {
                console.log("[assignBins] assignedBin error ", error);
                
              }
            } 
          }
          
          i = i + 1;
          if (i === countOforderIdGroup) {
            fnResult = {result: true, message: "Completed assign Bins"}
            setDisableSyncBtn(false);
            resolve(fnResult);
          }
        }
      } catch (error) {
        console.error("[assignBins] assign bin error", error);
        setDisableSyncBtn(false);
        reject(error);
      }
    });

  }

  function updateOrderQty() {
    return new Promise(async (resolve, reject) => {
      console.log("* updateOrderQty init");
      // console.info('[updateOrderQty] packingItems: ', packingItems)
      try {
        setDisableSyncBtn(true);
        bannerVariantRef.current ='info';
        setMsg('Updating order quantities init')
        console.log("[updateOrderQty] retrieving packing items");
        let fnResult = initialFnResult;
        let tempPackingItems = [];
        let orderIdGroup = {};
        
        const results = await getPackingItem({condition: {isActive: true, source: 'shipstation'}});
        console.info("[assignBins] getPackingItem results: ", results)
        tempPackingItems = _.cloneDeep(results);
        orderIdGroup = _.groupBy(tempPackingItems, "orderId");
        queryClient.setQueryData(["packingItems"], tempPackingItems);

        // console.info("[updateOrderQty] orderIdGroup", orderIdGroup);
        let countOforderIdGroup = Object.keys(orderIdGroup).length;
        console.info("[updateOrderQty] countOforderIdGroup", countOforderIdGroup);

        if(countOforderIdGroup > 0) {
          let i = 0;
          console.log('[updateOrderQty] orderIdGroup loop run')
          for (let orderId in orderIdGroup) {
            console.log(`[updateOrderQty] ${i+1}/${countOforderIdGroup} orderId: ${orderId}`)
            const order = orderIdGroup[orderId];
            // console.info("[updateOrderQty] order: ", order);
            const sumOrderQty = _.sumBy(order, "quantity");
  
            await getLocalOrder(orderId)
              .then(async result => {
                // console.info("[updateOrderQty] result: ", result);
                // console.log(`[updateOrderQty] quantity: ${result.quantity}, sumOrderQty: ${sumOrderQty}`);
                // console.log(`[updateOrderQty] sumOrderQty: ${sumOrderQty}`);
                if (result?.quantity !== sumOrderQty) {
                  await updateOrder({
                    condition:  { orderId },
                    update: { $set: { quantity: sumOrderQty, modifiedAt: new Date() } }
                  })
                    .then(async () => {
                      console.log(`[updateOrderQty] Qty: ${sumOrderQty} was saved: orderId ${orderId}`);
                    })
                    .catch((error) => {
                      console.error("[updateOrderQty] updateOrder error", error);
                      const retVal = errorHandler(error);
                      setMsg(retVal)
                      return
                    });
                }
              }).catch(error => {
                console.log('[updateOrderQty] getLocalOrder error: ', error)
                const retVal = errorHandler(error);
                setMsg(retVal)
              })
  
            i = i + 1;
            if (i === countOforderIdGroup) {
              fnResult = {result: true, message: "Completed updating order quantity"}
              setDisableSyncBtn(false);
              resolve(fnResult);
            }
          }
        } else {
          console.log('[updateOrderQty] Nothing to update order quantity')
          setDisableSyncBtn(false);
          fnResult = {result: true, message: "Nothing to update order quantity"}
          setDisableSyncBtn(false);
          resolve(fnResult);
        }
      } catch (error) {
        console.error("[updateOrderQty] error: ", error);
        setDisableSyncBtn(false);
        reject(error);
      }
    });
  };

  function stepHandler(id) {
    console.log("* stepHandler init")
    console.log('[stepHandler] step: ', id, typeof id)
    bannerVariantRef.current = 'info';
    switch(id) {
      case 1:
        fetchShipStationOrders().then(result => {
          setMsg(result.message)
          if(result.result) setCurrentStep(1)
        })
        break;
      case 2:
        inActiveLocalOrders()
          .then(result => {
            setMsg(result.message)
            return createLocalOrders()
          })
          .then(result => {
            setMsg(result.message)
            if(result.result) setCurrentStep(2)
          })
        break;
      case 3:
        createItems().then(result => {
          setMsg(result.message)
          if(result.result) setCurrentStep(3)
        })
        break;
      case 4:
        inActivatePackingItems()
          .then(result => {
            setMsg(result.message)
            return createPackingItems()
          })
          .then(result => {
            setMsg(result.message)
            setCurrentStep(4)
          })
        break;
      case 5:
        assignBins().then(result => {
          setMsg(result.message)
          setCurrentStep(5)
        })

        break;     
    
      case 6:
        updateOrderQty().then(result => {
          setMsg(result.message)
          if(result.result) {
            setCurrentStep(6);
            window.localStorage.removeItem('ss_orders');
            setMsg('Done syncing orders')
            toast.info('Click to move work orders', {
              autoClose: false,
              position: 'bottom-right',
              onClose: () => history.push('/work-orders')
            })
          }
        })
        
        break;  
      default:
        break;
    }
  }

  return <>
      <Title title={title} />
      <PageMainHeader 
        title={title} 
        user={user} 
        settings={settings} 
      />
      <ToastContainer theme='dark'/>
      <section className="primary section-sync-orders">
        <PageMenuHeader>
          <>
            <Button
              variant="primary"
              onClick={() => { 
                syncOrdersFlag.current = "front"
                setTimeout(() => {
                  setOpenConfirmSyncBanner(true) 
                }, 1000);
              }}
              leftGlyph={<Icon glyph="Download" />}
              disabled={disableSyncBtn}
              className="swing-icon"
              > Sync Orders
            </Button>
            
            <Button
              variant="danger"
              onClick={() => { 
                syncOrdersFlag.current = "back"
                setTimeout(() => {
                  setOpenConfirmSyncBanner(true) 
                }, 1000);
              }}
              leftGlyph={<Icon glyph="Download" />}
              disabled={disableSyncBtn}
              className="swing-icon"
              > Sync Orders:Backend
            </Button>
          </>
          
        </PageMenuHeader>
        <div className="card p-10 mb-10">
          <Banner variant={bannerVariantRef.current}>
            {msg} <GridLoader color={"#09804C"} size={5} width={20} loading={disableSyncBtn}/>
          </Banner>
        </div>
        <div className="card p-10">
          <Stepper currentStep={currentStep} maxDisplayedSteps={6} completedStepsShown={6}>
            {steps.map((s, index) => {
              return <span key={s.id} onClick={() => stepHandler(s.id)} className="step-handler">{index+1}. {s.name}</span>
            })}
          </Stepper>
        </div>
        <div className='card p-10 mt-10 console-card'>
          <b>Console</b>
          <div className="console-wrapper" ref={consoleRef}>
            <Console logs={logs} variant="dark" filter={['log', 'error']} />
          </div>
        </div>
        <ConfirmationModal
          open={openConfirmSyncBanner}
          onConfirm={() => {
            console.log("[ConfirmationModal:onConfirm] syncOrdersFlag: ", syncOrdersFlag.current)  
            if(syncOrdersFlag.current === 'front')
              syncOrders()
            else
              syncOrdersBackend()  
          }}
          onCancel={() => setOpenConfirmSyncBanner(false)}
          title="Confirm sync orders"
          buttonText="Confirm"
        >
          The sync orders may take some minutes to complete depending on the
          amount of data. A done message will appear upon completion.
        </ConfirmationModal>
      </section>
    </>
}