import React, { createContext, useState, useEffect, useCallback } from "react";
import { useQuery } from "react-query";
import socketIOClinet from "socket.io-client";
import _ from "lodash";
import { getSettings } from "utils/settings";
import { getSessions } from "utils/sessions";

const socket = socketIOClinet(process.env.REACT_APP_SOCKET_ENDPOINT);

export const SettingsContext = createContext();

const initialPlatens = [
  {
    name: "Large Platen(GTX H21)",
    code: "16x21",
    size: { width: 16, height: 21 },
    sizeCodeGTX: 0,
    printers: ["GTX"],
  },
  {
    name: "Large Platen",
    code: "16x20",
    size: { width: 16, height: 20 },
    sizeCodeGTX: null,
    printers: ["Epson"],
  },
  {
    name: "Large Platen(GTX H18)",
    code: "16x18",
    size: { width: 16, height: 18 },
    sizeCodeGTX: 1,
    printers: ["GTX"],
  },
  {
    name: "Medium Platen",
    code: "14x16",
    size: { width: 14, height: 16 },
    sizeCodeGTX: 2,
    printers: ["Epson", "GTX"],
  },
  {
    name: "Small Platen",
    code: "10x12",
    size: { width: 10, height: 12 },
    sizeCodeGTX: 3,
    printers: ["Epson", "GTX"],
  },
  {
    name: "Extra Small Platen",
    code: "7x8",
    size: { width: 7, height: 8 },
    sizeCodeGTX: 4,
    printers: ["Epson", "GTX"],
  },
  {
    name: "Sleeve Platen",
    code: "4x4",
    size: { width: 4, height: 4 },
    sizeCodeGTX: null,
    printers: ["Epson"],
  },
];
const SettingsContextProvider = (props) => {
  const _hostname = window.localStorage.getItem("_hostname");
  // console.log("_hostname: ", _hostname);

  _.isNil(_hostname) && socket.emit("on-get-hostname");

  const [platens, setPlatens] = useState([]);
  const [media, setMedia] = useState([]);

  const { data: settings } = useQuery("settings", getSettings, {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 10,
  });

  const { data: sessions } = useQuery("sessions", getSessions, {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 10,
  });

  const getHostnameCallback = useCallback(() => {
    socket.on("on-get-hostname-result", (result) => {
      console.log(">> on-get-hostname-result: ", result);
      console.log("_hostname in localStorage: ", _hostname);
      if (!_hostname) {
        window.localStorage.setItem("_hostname", result);
      } else {
        if (result !== _hostname) {
          window.localStorage.setItem("_hostname", result);
        }
      }
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getHostnameCallback();
    return () => {
      socket.removeAllListeners(["on-get-hostname-result"]);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (settings) {
      if (settings?.platens?.length > 0) {
        setPlatens(settings.platens);
      } else {
        setPlatens(initialPlatens);
      }

      if (settings?.media?.length > 0) {
        setMedia(settings.media);
      }
    }
  }, [settings]);

  return (
    <SettingsContext.Provider
      value={{ settings, platens, media, hostname: _hostname, sessions }}
    >
      {props.children}
    </SettingsContext.Provider>
  );
};

export default SettingsContextProvider;
